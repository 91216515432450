import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/layout';

export const query = graphql`
  query {
    allWpPost(
    limit: 10000
    filter: {categories: {nodes: {elemMatch: {slug: {eq: "brigady"}}}}}
    ) {
      nodes {
        id
        uri
        title
        excerpt
      }
    }
  }
`;

const Blog = ({ data }) => {
  const posts = data.allWpPost.nodes;

  return (
    <Layout>
      {posts.map((post) => (
        <article key={post.id}>
          <h2>
            <Link
              to={`/aktuality${post.uri}`}
              dangerouslySetInnerHTML={{ __html: post.title }}
            ></Link>
          </h2>
          <div
            dangerouslySetInnerHTML={{
              __html: post.excerpt.replace(
                /\[…\]|\[&hellip;\]|\[...]$/,
                `<a class="odkaz" href="/aktuality${post.uri}">Více zde...</a>`
              ),
            }}
          />
        </article>
      ))}
    </Layout>
  );
};

export default Blog;
